import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Spinner,
  Switch,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectRawMeters } from '../meterSetupSlice';
import { Controller, FieldArrayWithId, useFieldArray, useWatch } from 'react-hook-form';
import MeterCTSelect from '../../../../common/components/MeterCTSelect';
import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';
import SelectedCircuitsCard, { EmptySelectedCircuitsCard } from './SelectedCircuitsCard';
import { ArrowForwardIcon, MinusIcon, WarningIcon } from '@chakra-ui/icons';
import Counter from '../../../../common/components/Counter';
import SelectBottomDrawer from '../../../../common/components/SelectBottomDrawer';
import { selectAssignments } from '../../system-details/systemDetailsSlice';
import { CTConfigFormData } from './ct-configuration-form-types';
import { TabComponentProps } from './CTConfigurationContainer';
import { NON_LOAD_CIRCUIT_TYPES } from '../meter-setup-helpers';
import { CONTROLLED_LOAD_ASSIGNMENT_TYPES, SIDENAV_WIDTH } from '../../../../common/constants';
import { CIRCUIT_TYPE_TO_ICON } from 'clipsal-cortex-icons/src/circuit-type-to-icon-map';
import { COMMON_NEXT_BUTTON_PROPS } from './ct-configuration-helpers';
import { Assignment } from '../../../../api/api-assignments';
import FieldIconWrapper from './FieldIconWrapper';
import { EMPTY_LOAD_TEMPLATE } from './ct-configuration-helpers';
import { useReservedCTsForLoad } from './useReservedCTsForLoad';
import InfoPopover from 'clipsal-cortex-ui/src/components/InfoPopover';

export default function LoadCTConfiguration(props: TabComponentProps) {
  const { control, updateCTFunctions, isUpdatingCTs, circuitState } = props;

  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );

  const { fields, append, remove } = useFieldArray({
    name: `load` as const,
    control,
  });
  const { hasEVCharger } = useWatch({ control });
  const [expandedIndex, setExpandedIndex] = useState<number>(fields.length - 1);

  const assignments = useSelector(selectAssignments);

  useEffect(() => {
    setExpandedIndex(fields.length - 1);
  }, [fields.length]);

  // remove grid, solar, not_set, consumption from assignments
  const filteredAssignments = useMemo(
    () => assignments.filter((assignment) => ![...NON_LOAD_CIRCUIT_TYPES].includes(assignment.assignment)),
    [assignments]
  );

  const handleDeleteLoad = useCallback(
    (loadIndex: number, loadName: string) => {
      if (window.confirm(`Are you sure you want to delete ${loadName || `Load ${loadIndex + 1}`}?`)) {
        remove(loadIndex);
      }
    },
    [remove]
  );

  return (
    <Flex direction="column" data-testid="load-tab-panel">
      <Flex align="center" justify="space-between" px={[1, 2]} pb={4}>
        <Box>
          <Text pr={8}>
            How many different loads do you want to present in Cortex app?{' '}
            {hasEVCharger && (
              <InfoPopover>
                <Text fontSize="md">
                  This is for any other important loads that need to be monitored. The EV charger load has already been
                  set up so there's no need to add it here.
                </Text>
              </InfoPopover>
            )}
          </Text>
        </Box>
        <Counter
          value={fields.length}
          incrementAriaLabel="Add Load"
          decrementAriaLabel="Remove Load"
          onIncrement={() => append(EMPTY_LOAD_TEMPLATE, { shouldFocus: false })}
          onDecrement={() => handleDeleteLoad(fields.length - 1, fields[fields.length - 1].loadName)}
        />
      </Flex>
      <Accordion
        defaultIndex={fields.length - 1}
        allowToggle
        index={expandedIndex}
        onChange={(clickedIndex: number) => setExpandedIndex(clickedIndex)}
      >
        {fields.map((field, index) => {
          return (
            <LoadAccordionItemAndPanel
              key={`${field.id}-${index}`}
              {...{ ...props, loadIndex: index, field, handleDeleteLoad, filteredAssignments }}
            />
          );
        })}
      </Accordion>
      <Flex
        position={'fixed'}
        bottom={16}
        left={0}
        zIndex={99}
        width="100%"
        justifyContent={'center'}
        paddingLeft={[0, 0, 0, 0, SIDENAV_WIDTH]}
        pointerEvents="none"
      >
        <Flex width={isMobileViewport ? '100%' : '60%'}>
          <Button
            data-testid="submit-load-button"
            aria-label="Submit Load"
            rightIcon={isUpdatingCTs ? <Spinner size="sm" /> : <ArrowForwardIcon />}
            disabled={isUpdatingCTs || !!circuitState.pendingMeterIds.length}
            {...COMMON_NEXT_BUTTON_PROPS}
            onClick={() => updateCTFunctions['load']()}
          >
            {!fields.length ? 'Proceed without adding load' : 'Next'}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

type LoadAccordionItemAndPanelProps = TabComponentProps & {
  loadIndex: number;
  field: FieldArrayWithId<CTConfigFormData, 'load', 'id'>;
  handleDeleteLoad: (loadIndex: number, loadName: string) => void;
  filteredAssignments: Assignment[];
};

function LoadAccordionItemAndPanel(props: LoadAccordionItemAndPanelProps) {
  const { loadIndex, field, handleDeleteLoad, setValue, errors, filteredAssignments, control, trigger, register } =
    props;
  const errorsAtIndex = errors?.load?.[loadIndex];
  const [isLoadNameAlertExpanded, setIsLoadNameAlertExpanded] = useState(true);
  const [isLoadAlertHidden, setIsLoadAlertHidden] = useState(!!localStorage.getItem('loadNameAlertDismissed'));
  const checkIconColor = useColorModeValue('rgb(26, 32, 44)', 'rgb(216, 216, 217)');
  // nested fields do not update on changes. So need to watch them for changes
  const loadName = useWatch({ control, name: `load.${loadIndex}.loadName` });
  const loadType = useWatch({ control, name: `load.${loadIndex}.loadType` });

  return (
    <AccordionItem key={`${field.id}-${loadIndex}`} data-testid={`load-${loadIndex}`}>
      <AccordionButton role={'button'} as={Box} px={[1, 4]}>
        <Flex w={'100%'} justify={'space-between'} align={'center'}>
          <Flex direction="column">
            <Flex align="center">
              <Heading size="sm" fontWeight="500">
                {loadName || `Load ${loadIndex + 1}`}
              </Heading>
              {errorsAtIndex && <WarningIcon color={'red.500'} w={5} h={5} ml={2} />}
            </Flex>
            <TotalCTLabel {...props} />
          </Flex>
          <Flex align={'center'}>
            <Button
              variant={'ghost'}
              onClick={() => handleDeleteLoad(loadIndex, loadName)}
              size={'xs'}
              ml={2}
              colorScheme="red"
              aria-label="Delete Load Appliance"
            >
              Remove
            </Button>
            <AccordionIcon />
          </Flex>
        </Flex>
      </AccordionButton>

      <AccordionPanel pb={4} px={[1, 4]}>
        {errorsAtIndex && (
          <Text color="red.500" mt={2} mb={2}>
            {(errorsAtIndex as any)?.circuits?.message + ` for ${loadName || `Load ${loadIndex + 1}`}`}
          </Text>
        )}

        {!isLoadAlertHidden && (
          <Alert data-testid={`load-${loadIndex}-name-alert`} borderRadius={12} border={'1px solid lightgrey'}>
            <Box w={'100%'}>
              <Flex alignItems="center">
                <AlertIcon />
                <AlertTitle fontSize={['sm', 'md']}>Load name will be shown on Cortex</AlertTitle>
                <IconButton
                  data-testid={`load-${loadIndex}-collapse-alert-button`}
                  size={'xs'}
                  ml={'auto'}
                  pb={2}
                  variant="unstyled"
                  aria-label="Collapse alert"
                  icon={<MinusIcon />}
                  onClick={() => setIsLoadNameAlertExpanded(!isLoadNameAlertExpanded)}
                ></IconButton>
              </Flex>

              <Collapse in={isLoadNameAlertExpanded}>
                <Box mx={8} my={2}>
                  <AlertDescription data-testid={`load-${loadIndex}-alert-description`} fontSize={['sm', 'md']}>
                    The customer will see the load name on Cortex, please make sure it's the right name.
                  </AlertDescription>
                </Box>
                <Checkbox
                  data-testid={`load-${loadIndex}-hide-alert-checkbox`}
                  borderColor={checkIconColor}
                  onChange={(e) => {
                    localStorage.setItem('loadNameAlertDismissed', e.currentTarget.checked ? 'true' : 'false');
                    setIsLoadAlertHidden(e.currentTarget.checked);
                  }}
                  isChecked={isLoadAlertHidden}
                  mb={3}
                >
                  <Text ml={2}> Don't show me again</Text>
                </Checkbox>
              </Collapse>
            </Box>
          </Alert>
        )}

        <Controller
          control={control}
          name={`load.${loadIndex}.loadType` as 'load.0.loadType'}
          defaultValue={field.loadType}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <FormControl my={3} isInvalid={invalid}>
              <FormLabel>Load Type</FormLabel>
              <SelectBottomDrawer
                value={value || field.loadType}
                options={filteredAssignments}
                labelValueAttributes={['display_name', 'assignment']}
                isInvalid={!!errorsAtIndex?.loadType}
                onChange={(value: string, label: string) => {
                  onChange(value);
                  if (invalid) trigger(`load.${loadIndex}.loadType` as `load.${number}.loadType`);
                  setValue(`load.${loadIndex}.loadName` as `load.0.loadName`, label, {
                    shouldValidate: !!errorsAtIndex?.loadName,
                  });
                }}
                isSearchable
                searchPlaceHolder="Search load type"
                placeholder="Select a load type"
              />
              <FormErrorMessage>{errorsAtIndex?.loadName?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <FormControl my={3} isInvalid={!!errorsAtIndex?.loadName}>
          <FormLabel>Load Name</FormLabel>
          <Input
            defaultValue={field.loadName}
            placeholder={`Enter load name`}
            type="text"
            {...register(`load.${loadIndex}.loadName` as const)}
          />
          <FormErrorMessage>{errorsAtIndex?.loadType?.message}</FormErrorMessage>
        </FormControl>

        {CONTROLLED_LOAD_ASSIGNMENT_TYPES.includes(loadType) && <ControlledLoadSwitch {...props} />}

        <NestedLoadCTConfigurationFieldArray
          loadApplianceIndex={loadIndex}
          watchedLoadData={{ loadName, loadType }}
          {...props}
        />
      </AccordionPanel>
    </AccordionItem>
  );
}

// This component is separated in order to contain the re-render caused by watched load circuits
function TotalCTLabel({ control, loadIndex }: LoadAccordionItemAndPanelProps) {
  // nested fields do not update on changes. So need to watch them for changes
  const loadCircuits = useWatch({ control, name: `load.${loadIndex}.circuits` });
  const totalCircuit = loadCircuits?.length || 0;

  return (
    <Text color="dusk.100" mt={2}>
      {totalCircuit ? `${totalCircuit} CT${totalCircuit > 1 ? "'s" : ''} added` : "Add CT's"} to the Load
    </Text>
  );
}

export function NestedLoadCTConfigurationFieldArray({
  control,
  loadApplianceIndex,
  register,
  circuitState,
  onCircuitChange,
  errors,
  trigger,
  watchedLoadData,
}: TabComponentProps & {
  loadApplianceIndex: number;
  watchedLoadData: { loadName: string; loadType: string };
}) {
  const rawMeters = useSelector(selectRawMeters);
  const { fields, append, remove } = useFieldArray({
    name: `load.${loadApplianceIndex}.circuits` as `load.0.circuits`,
    control,
  });
  const { values } = circuitState;
  const toast = useToast();
  const { loadType, loadName = `Load ${loadApplianceIndex}` } = watchedLoadData;

  const Icon = CIRCUIT_TYPE_TO_ICON[loadType in CIRCUIT_TYPE_TO_ICON ? loadType : 'load_powerpoint'];
  const FieldIcon = (
    <FieldIconWrapper>
      <Icon w={6} h={6} />
    </FieldIconWrapper>
  );

  useEffect(() => {
    // show toast errors as the form can be longer and
    // error lables can be harder to notice.
    const errorsAtIndex = errors.load?.[loadApplianceIndex];
    const loadNameInError = loadName || `Load ${loadApplianceIndex + 1}`;
    if (errorsAtIndex) {
      const { loadName, loadType, circuits } = errorsAtIndex;
      toast({
        title: `Missing ${loadName || loadType ? 'field' : 'CT'} in ${loadNameInError}`,
        description: ((loadName || loadType || circuits) as any)?.message + ` for ${loadNameInError}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.load]);

  const reservedCts = useReservedCTsForLoad(control, loadApplianceIndex);

  function handleSelectCircuit(circuitData: ChannelData) {
    // returns index if found otherwise returns -1
    const indexOfCTField = fields.findIndex((selectedCT) => circuitData.ww_circuit_id === selectedCT.ww_circuit_id);

    if (indexOfCTField >= 0) {
      remove(indexOfCTField);
    } else {
      const channel = {
        ...circuitData,
        circuit_name: loadName,
        clipsal_monitors: loadType,
      };
      append(channel, { shouldFocus: false });
    }

    // revalidate form if there is any errors for load tab
    if (errors?.load) trigger(`load.${loadApplianceIndex}.circuits` as `load.${number}.circuits`);
  }

  if (!loadType) return <></>;

  return (
    <Box data-testid="load-meter-select">
      <Heading size="sm" fontWeight="500" my={8}>
        {`Select which CT's are measuring the ${loadName}`}
      </Heading>
      {Object.values(rawMeters).map((meter, idx) => {
        return (
          <MeterCTSelect
            meterIndex={idx}
            key={loadApplianceIndex + meter.ww_device_id}
            meter={meter}
            onCTClick={(circuitData) => handleSelectCircuit(circuitData)}
            selectedCTs={fields}
            reservedCTs={reservedCts}
          />
        );
      })}

      <Box mb={10}>
        {fields.map((field, idx) => {
          const switchValues = values.find((value) => value.circuitId === field.ww_circuit_id);
          if (!switchValues) throw new Error(`Unable to find switch value for circuit id ${field.ww_circuit_id}`);

          return (
            <SelectedCircuitsCard
              key={field.ww_circuit_id}
              source={`load.${loadApplianceIndex}.circuits` as `load.${number}.circuits`}
              field={field}
              index={idx}
              register={register}
              onDismiss={() => remove(idx)}
              iconComponent={FieldIcon}
              onCircuitChange={onCircuitChange}
              polarityValue={switchValues.polarity}
              voltageRefValue={switchValues.voltageReference}
              ctRating={switchValues.ctRating}
            />
          );
        })}
        {!fields.length && <EmptySelectedCircuitsCard iconComponent={FieldIcon} />}
      </Box>
    </Box>
  );
}

type ControlledLoadSwitchProps = {
  loadIndex: number;
} & TabComponentProps;

function ControlledLoadSwitch(props: ControlledLoadSwitchProps) {
  const { register, loadIndex, control } = props;
  const isControlledLoad = useWatch({ control, name: `load.${loadIndex}.isControlledLoad` });

  return (
    <Flex align="center" justify="space-between" mt={4}>
      <Heading size="sm">Is this a controlled load circuit?</Heading>

      <Flex align="center" ml={2}>
        <Text>{isControlledLoad ? 'Yes' : 'No'}</Text>

        <Switch
          ml={2}
          colorScheme="primaryBranding"
          size="lg"
          defaultChecked={isControlledLoad}
          {...register(`load.${loadIndex}.isControlledLoad` as const)}
        />
      </Flex>
    </Flex>
  );
}

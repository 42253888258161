import { InstalledDevice, InstalledDeviceToSave } from '../../../api/api-device';
import { InverterOrientation, InverterOrientationToSave } from '../../../api/api-orientation';
import { GenericDeviceFormData, InverterFormData, InverterOrientationData } from './system-details-form-types';
import { DeviceMetadata } from '../../../api/api-device-metadata';
import { SUNGROW_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

export function mapInvertersToForm(
  inverters: (InstalledDevice & { orientations: InverterOrientation[] })[]
): InverterFormData[] {
  return inverters.map<InverterFormData>((inverter) => {
    let totalPanels = 0;

    const orientations = inverter.orientations.map((orientation) => {
      totalPanels += orientation.number_of_modules || 0;
      const orientationPower = orientation.module_rated_power;
      return {
        inverterId: orientation.inverter_id,
        orientationId: orientation.orientation_id,
        panelPower: orientationPower,
        numOfModules: orientation.number_of_modules,
        cardinality: orientation.cardinality,
        tilt: orientation.tilt,
      };
    });

    return {
      inverterId: inverter.row_id ?? null,
      hasSolarPanels: !!orientations.length,
      hasDCCoupledBattery: !!inverter.is_hybrid_inverter,
      hasSecondACOutput: true,
      manufacturer: inverter.meta_data.manufacturer_id,
      model: inverter.meta_data.device_metadata_id,
      serialNumber: inverter.serial_number ?? '',
      siteIdentifier: inverter.site_identifier ?? '',
      apiKey: inverter.api_key ?? '',
      panelPower: orientations[0]?.panelPower || 0,
      totalPanels: totalPanels,
      orientations,
    };
  });
}

export function mapInvertersToAPI(
  inverters: InverterFormData[],
  siteId: number,
  modelsByManufacturerId: Record<string, DeviceMetadata[]>
): InstalledDeviceToSave[] {
  return inverters.map<InstalledDeviceToSave>((inverter) => {
    // The live data endpoint uses site_identifier to pull data, which is the same as serial number.
    // There is no point for the user to enter 2 fields with the same info.
    // So autopopulate site_identifier with serial_number for Sungrow inverters.
    const isSungrowInverter = inverter.manufacturer === SUNGROW_MANUFACTURER_ID;
    const siteIdentifier = isSungrowInverter ? inverter.serialNumber : inverter.siteIdentifier;

    return {
      row_id: inverter.inverterId ?? undefined,
      clipsal_solar_id: siteId,
      quantity: 1,
      serial_number: inverter.serialNumber,
      site_identifier: siteIdentifier,
      api_key: inverter.apiKey,
      is_hybrid_inverter: inverter.hasDCCoupledBattery,
      device_metadata_id: inverter.model,
      meta_data: modelsByManufacturerId[inverter.manufacturer].find((m) => m.device_metadata_id === inverter.model),
    };
  });
}

export function mapOrientationsToAPI(
  orientations: InverterOrientationData[],
  inverterId: number
): InverterOrientationToSave[] {
  return orientations.map<InverterOrientationToSave>((orientation) => ({
    orientation_id: orientation.orientationId ?? undefined,
    inverter_id: inverterId as number,
    cardinality: orientation.cardinality,
    tilt: orientation.tilt,
    number_of_modules: orientation.numOfModules,
    module_rated_power: orientation.panelPower,
  }));
}

export function mapOrientationsToForm(orientations: InverterOrientation[]): InverterOrientationData[] {
  return orientations.map((orientation) => ({
    inverterId: orientation.inverter_id,
    orientationId: orientation.orientation_id,
    panelPower: orientation.module_rated_power,
    numOfModules: orientation.number_of_modules,
    cardinality: orientation.cardinality,
    tilt: orientation.tilt,
  }));
}

export function mapBatteriesToForm(batteries: InstalledDevice[]): GenericDeviceFormData[] {
  return batteries.map((battery) => ({
    deviceId: battery.row_id ?? null,
    manufacturer: battery.meta_data.manufacturer_id,
    model: battery.meta_data.device_metadata_id,
    serialNumber: battery.serial_number,
  }));
}

export function mapBatteriesToAPI(
  batteries: GenericDeviceFormData[],
  siteId: number,
  modelsByManufacturerId: Record<string, DeviceMetadata[]>
): InstalledDeviceToSave[] {
  return batteries.map((battery) => ({
    row_id: battery.deviceId ?? undefined,
    clipsal_solar_id: siteId,
    quantity: 1,
    serial_number: battery.serialNumber,
    is_hybrid_inverter: false,
    api_key: '',
    device_metadata_id: battery.model,
    meta_data: modelsByManufacturerId[battery.manufacturer].find((m) => m.device_metadata_id === battery.model),
    site_identifier: null,
  }));
}

export function mapEVChargersToForm(evChargers: InstalledDevice[]): GenericDeviceFormData[] {
  return evChargers.map((evCharger) => ({
    deviceId: evCharger.row_id ?? null,
    manufacturer: evCharger.meta_data.manufacturer_id,
    model: evCharger.meta_data.device_metadata_id,
    serialNumber: evCharger.serial_number,
    siteIdentifier: evCharger.site_identifier,
  }));
}

export function mapEVChargersToAPI(
  evChargers: GenericDeviceFormData[],
  siteId: number,
  modelsByManufacturerId: Record<string, DeviceMetadata[]>
): InstalledDeviceToSave[] {
  return evChargers.map((evCharger) => ({
    row_id: evCharger.deviceId ?? undefined,
    clipsal_solar_id: siteId,
    quantity: 1,
    serial_number: evCharger.serialNumber,
    is_hybrid_inverter: false,
    api_key: '',
    device_metadata_id: evCharger.model,
    meta_data: modelsByManufacturerId[evCharger.manufacturer].find((m) => m.device_metadata_id === evCharger.model),
    site_identifier: evCharger.siteIdentifier ?? null,
  }));
}

import { ChannelData, WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { InstalledDevice } from '../../../../api/api-device';
import { CTConfigFormData } from './ct-configuration-form-types';
import { Appliance } from '../../../../api/api-appliance';
import { GRID_CIRCUIT_TYPES, HYBRID_CIRCUIT_TYPES, SOLAR_CIRCUIT_TYPES } from '../meter-setup-helpers';

export function mapMetersToCTConfigurationForm(
  meters: WattwatchersMeter[],
  inverters: InstalledDevice[],
  appliances: Appliance[],
  batteries: InstalledDevice[],
  evChargers: InstalledDevice[]
): CTConfigFormData {
  let hasAcCoupledInverter = false,
    hasDcCoupledInverter = false,
    totalDCCoupledInverters = 0;
  const hasBattery = !!batteries.length;
  const hasEVCharger = !!evChargers.length;

  // updating flags at the start as will be later used to populate appliances
  inverters.forEach((inverter) => {
    // two conditions as both type of inverters can co-exist
    hasDcCoupledInverter = hasDcCoupledInverter || !!inverter.is_hybrid_inverter;
    hasAcCoupledInverter = hasAcCoupledInverter || !inverter.is_hybrid_inverter;
    if (inverter.is_hybrid_inverter) totalDCCoupledInverters += 1;
  });

  const allMeterCircuits = meters.map((m) => m.circuits).flat();

  const gridAppliance = appliances.find((a) => GRID_CIRCUIT_TYPES.includes(a.appliance_type));
  const grid = gridAppliance
    ? allMeterCircuits.filter((c) => gridAppliance.circuits.includes(c.clipsal_circuit_id))
    : [];

  const { solarAppliances, loadAppliances, batteryAppliances, evChargerAppliances } = appliances.reduce<{
    solarAppliances: Appliance[];
    loadAppliances: Appliance[];
    batteryAppliances: Appliance[];
    evChargerAppliances: Appliance[];
  }>(
    (acc, appliance) => {
      if (SOLAR_CIRCUIT_TYPES.includes(appliance.appliance_type)) {
        acc.solarAppliances.push(appliance);
      } else if (appliance.appliance_type === 'battery_storage') {
        acc.batteryAppliances.push(appliance);
      } else if (!!appliance.control_device_id && appliance.appliance_type === 'load_ev_charger') {
        acc.evChargerAppliances.push(appliance);
      } else if (
        appliance.appliance_type && //ensures null values are not selected
        ![...GRID_CIRCUIT_TYPES, ...HYBRID_CIRCUIT_TYPES, 'not_set'].includes(appliance.appliance_type)
      ) {
        acc.loadAppliances.push(appliance);
      }
      return acc;
    },
    { solarAppliances: [], loadAppliances: [], batteryAppliances: [], evChargerAppliances: [] }
  );

  const solar: CTConfigFormData['solar'] = [];

  // only populate if there is ac coupled inverter
  if (hasAcCoupledInverter)
    inverters
      .filter((inverter) => !inverter.is_hybrid_inverter) // ignore hybrid inverter for solar
      .forEach((_, index) => {
        const solarAppliance = solarAppliances[index];

        let solarInverterData: CTConfigFormData['solar'][0] = { applianceId: null, circuits: [] };

        if (solarAppliance) {
          solarInverterData = {
            applianceId: solarAppliance.appliance_id,
            circuits: solarAppliance.circuits
              .map((circuitId) => allMeterCircuits.find((c) => c.clipsal_circuit_id === circuitId) as ChannelData)
              .filter((circuit) => !!circuit),
          };
        }

        solar.push(solarInverterData);
      });

  const load = loadAppliances.map((load) => {
    const circuits = load.circuits
      .map((circuitId) => allMeterCircuits.find((c) => c.clipsal_circuit_id === circuitId) as ChannelData)
      .filter((circuit) => !!circuit);
    return {
      applianceId: load.appliance_id,
      loadName: load.appliance_name,
      loadType: load.appliance_type,
      isControlledLoad: circuits[0]?.controlled_load_yn === 'Y',
      circuits,
    };
  });

  let battery: CTConfigFormData['battery'] = [];

  // only populate if there is a battery and avoid batteries of hybrid inverters
  if (batteries.length > totalDCCoupledInverters) {
    // remove any dc coupled batteries
    const totalBatteries = batteries.length - totalDCCoupledInverters;
    const filteredBatteries = batteries.filter((_, index) => index <= totalBatteries - 1);

    battery = filteredBatteries.map((_, index) => {
      const batteryAppliance = batteryAppliances[index];
      if (!batteryAppliance) {
        return { applianceId: null, circuits: [] };
      }

      return {
        applianceId: batteryAppliance.appliance_id,
        circuits: batteryAppliance.circuits
          .map((circuitId) => allMeterCircuits.find((c) => c.clipsal_circuit_id === circuitId) as ChannelData)
          .filter((circuit) => !!circuit),
      };
    });
  }

  let inverter: ChannelData[] = [],
    backup: ChannelData[] = [];

  // only populate if there is dc coupled inverter
  if (hasDcCoupledInverter) {
    const inverterAppliance = appliances.find((a) => a.appliance_type === 'hybrid_inverter');
    inverter = inverterAppliance
      ? allMeterCircuits.filter((c) => inverterAppliance.circuits.includes(c.clipsal_circuit_id))
      : [];

    const backupAppliance = appliances.find((a) => a.appliance_type === 'backup_circuit');
    backup = backupAppliance
      ? allMeterCircuits.filter((c) => backupAppliance.circuits.includes(c.clipsal_circuit_id))
      : [];
  }

  const evCharger: CTConfigFormData['evCharger'] = evChargers.map((evDevice, index) => {
    const evAppliance = evChargerAppliances[index];
    if (!evAppliance) {
      return {
        applianceId: null,
        loadName: 'EV Charger' + (index > 0 ? ` ${index + 1}` : ''),
        controlDeviceRowId: evDevice.row_id!,
        circuits: [],
      };
    }
    const circuits = evAppliance.circuits
      .map((circuitId) => allMeterCircuits.find((c) => c.clipsal_circuit_id === circuitId) as ChannelData)
      .filter((circuit) => !!circuit);
    return {
      applianceId: evAppliance.appliance_id,
      loadName: evAppliance.appliance_name,
      circuits,
      controlDeviceRowId: evDevice.row_id!,
    };
  });

  return {
    grid,
    solar,
    battery,
    load,
    evCharger,
    hybrid: { inverter, backup },
    hasAcCoupledInverter,
    hasDcCoupledInverter,
    hasBattery,
    hasEVCharger,
    hasSecondACOutput: !!backup.length,
  };
}

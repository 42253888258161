import { Box, Flex, Grid, Heading, Text, useBreakpointValue, useColorModeValue, Center } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Card from 'clipsal-cortex-ui/src/components/card/Card';
import DateRangeTypePicker, { DateRangeType } from './DateRangeTypePicker';
import PeriodPicker from './PeriodPicker';
import { FleetSystemHealthStatus } from '../../api/api-fleet-data';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import MobileTopNav from '../../common/components/MobileTopNav';
import { BOTTOM_NAV_HEIGHT, CLIPSAL_TENANT_ID, MONTHS, WEEKDAYS } from '../../common/constants';
import FleetInfoStats from './FleetInfoStats';
import { formatDate, formatFull24TimeTo12Hours, formatTime } from '../../utils/datetime-utils';
import FleetDashboardProfileInfo from './FleetDashboardProfileInfo';
import { get } from '../../api/api-helpers';
import { utcToZonedTime } from 'date-fns-tz';
import { getOrdinalSuffix } from '../../utils/number-formatting';
import { PaginatedResponse } from 'clipsal-cortex-types/src/common/types';
import { SiteData } from '../../api/api-site';
import NewUserDashboardTemplate from '../../common/components/NewUserDashboardTemplate';
import FleetChartDesktopImg from '../../assets/images/fleet_chart_desktop.svg';
import FleetChartMobileImg from '../../assets/images/fleet_chart_mobile.svg';
import AlertsNotificationIconButton from '../../common/components/AlertsNotificationIconButton';
import { useSelector } from 'react-redux';
import { selectUser } from '../user/userSlice';
import { FIRST_YEAR_OF_DATA, INITIAL_SYSTEM_HEALTH_STATUS } from './fleet-dashboard-helpers';
import FleetDashboardWidgets from './widgets/FleetDashboardWidgets';
import UsageChart from './UsageChart';
import useFleetData from './useFleetData';
import { lastDayOfMonth } from 'date-fns';
import { getFirstDayOfWeek } from 'clipsal-cortex-utils/src/calculations/date-utils';
import { TenantSelect } from '../../common/components/TenantSelect';

const CHART_HEIGHT_PIXELS = 400;

// @TODO: refactor with RTK query for caching and simplicity
export default function FleetDashboard() {
  const [selectedDateRangeType, setSelectedDateRangeType] = useState<DateRangeType>(DateRangeType.Day);
  const { fleetData, fetchAndSetFleetEnergy, fetchAndSetFleetPower } = useFleetData();
  const user = useSelector(selectUser);
  const [selectedTenantId, setSelectedTenantId] = useState<number>(user.tenant_id);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [systemHealthStatus, setSystemHealthStatus] = useState(INITIAL_SYSTEM_HEALTH_STATUS);
  const [isWidgetDataLoaded, setIsWidgetDataLoaded] = useState(false);
  const [isChartLoaded, setIsChartLoaded] = useState(false);
  const [{ numActiveSites, numPendingSites, isNumSitesLoaded }, setNumSites] = useState({
    numActiveSites: 0,
    numPendingSites: 0,
    isNumSitesLoaded: false,
  });
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      xl: false,
    },
    { ssr: false }
  );
  const [selectedPeriod, setSelectedPeriod] = useState(formatDate(new Date()));
  const isFirstRender = useRef(true);
  const { iconBackground, background } = useColorModeValue(
    {
      iconBackground: 'white',
      background: 'white',
    },
    {
      iconBackground: 'whiteAlpha.200',
      background: 'gray.900',
    }
  );

  // Common function to fetch fleet data and totals, then set them in state to update the UI.
  const fetchAndSetFleetWidgetData = useCallback(async (tenantId: number) => {
    const [lastUpdated, activeSites, pendingSites, systemHealthStatus] = await Promise.all([
      get<{ last_updated: string | null }>(`/fleet/tenants/${tenantId}/fleet_energy_last_updated`),

      // Fetch with a limit of 0, so the query is just for COUNT.
      get<PaginatedResponse<SiteData>>(
        `/fleet/sites?limit=0&offset=0&commissioning_status=ACTIVE${
          tenantId !== CLIPSAL_TENANT_ID ? '&tenant_id=' + tenantId.toString() : ''
        }`
      ),
      get<PaginatedResponse<SiteData>>(
        `/fleet/sites?limit=0&offset=0&commissioning_status=PENDING${
          tenantId !== CLIPSAL_TENANT_ID ? '&tenant_id=' + tenantId.toString() : ''
        }`
      ),

      // Fetch System Health Status Counts
      get<FleetSystemHealthStatus>(`/fleet/fleet_operating_status`),
    ]);

    setSystemHealthStatus(systemHealthStatus);
    setLastUpdated(lastUpdated.last_updated);
    setNumSites({
      numActiveSites: activeSites.item_count,
      numPendingSites: pendingSites.item_count,
      isNumSitesLoaded: true,
    });
    setIsWidgetDataLoaded(true);
  }, []);

  // First load from API
  useEffect(() => {
    async function fetchAPI() {
      const nowUserTimezone = new Date();

      // Set to midnight user's time
      nowUserTimezone.setHours(0, 0, 0, 0);
      const nextDay = new Date(nowUserTimezone.getTime());
      nextDay.setDate(nextDay.getDate() + 1);
      await fetchAndSetFleetPower(selectedTenantId, nowUserTimezone.toISOString(), nextDay.toISOString());
      setIsChartLoaded(true);
      isFirstRender.current = false;
    }
    if (isFirstRender.current) {
      fetchAPI();
    }
  }, []);

  useEffect(() => {
    async function refreshWidgets() {
      await fetchAndSetFleetWidgetData(selectedTenantId);
    }
    refreshWidgets();
  }, [selectedTenantId]);

  // Effect handler for state changes
  useEffect(() => {
    if (!isFirstRender.current) {
      async function updateChart() {
        setIsChartLoaded(false);

        // Each type of range has some custom logic required to get the start and end date query parameters.
        const selectedDateRangeTypeToCaller: Record<DateRangeType, () => void> = {
          [DateRangeType.Day]: async () => {
            const zonedTime = new Date(selectedPeriod);
            zonedTime.setHours(0, 0, 0, 0); // Set to midnight user's time
            const fromDatetime = zonedTime.toISOString();
            zonedTime.setDate(zonedTime.getDate() + 1);
            const toDatetime = zonedTime.toISOString();
            await fetchAndSetFleetPower(selectedTenantId, fromDatetime, toDatetime);
          },
          [DateRangeType.Week]: async () => {
            const firstDayOfWeek = getFirstDayOfWeek(new Date(selectedPeriod));
            const mutableFirstDayOfWeek = new Date(firstDayOfWeek);
            const lastDayOfWeek = new Date(mutableFirstDayOfWeek.setDate(mutableFirstDayOfWeek.getDate() + 6));
            await fetchAndSetFleetEnergy(
              selectedTenantId,
              formatDate(firstDayOfWeek),
              formatDate(lastDayOfWeek),
              'DAY'
            );
          },
          [DateRangeType.Month]: async () => {
            const startDate = new Date(selectedPeriod);
            const endDate = lastDayOfMonth(startDate);
            startDate.setDate(1);
            await fetchAndSetFleetEnergy(selectedTenantId, formatDate(startDate), formatDate(endDate), 'DAY');
          },
          [DateRangeType.Year]: async () => {
            const selectedYear = new Date(selectedPeriod).getFullYear();
            const start = `${selectedYear}-01-01`;
            const end = `${selectedYear}-12-31`;

            await fetchAndSetFleetEnergy(selectedTenantId || user.tenant_id, start, end, 'MONTH');
          },
          [DateRangeType.All]: async () => {
            const start = `${FIRST_YEAR_OF_DATA}-01-01`;
            const end = formatDate(new Date());

            await fetchAndSetFleetEnergy(selectedTenantId || user.tenant_id, start, end, 'YEAR');
          },
        };

        await selectedDateRangeTypeToCaller[selectedDateRangeType]();
        setIsChartLoaded(true);
      }

      updateChart();
    }
  }, [selectedDateRangeType, selectedPeriod, fetchAndSetFleetWidgetData, selectedTenantId]);

  function getDisplayedDateTimeString(lastUpdatedUTC: string | null) {
    if (!lastUpdatedUTC) return 'N/A';

    const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedTime = utcToZonedTime(new Date(lastUpdatedUTC), userTZ);

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const isToday =
      zonedTime.getFullYear() === today.getFullYear() &&
      zonedTime.getMonth() === today.getMonth() &&
      zonedTime.getDate() === today.getDate();
    const isYesterday =
      zonedTime.getFullYear() === yesterday.getFullYear() &&
      zonedTime.getMonth() === yesterday.getMonth() &&
      zonedTime.getDate() === yesterday.getDate();

    let timeString = '';

    // Add formatted date information
    if (isToday) {
      timeString = 'today at';
    } else if (isYesterday) {
      timeString = 'yesterday at';
    } else {
      timeString = `${WEEKDAYS[zonedTime.getDay()]}, ${zonedTime.getDate()}${getOrdinalSuffix(zonedTime.getDate())} ${
        MONTHS[zonedTime.getMonth()]
      } at`;
    }

    // Add time and timezone
    timeString += ` ${formatFull24TimeTo12Hours(formatTime(zonedTime))} (${userTZ})`;
    return timeString;
  }

  return (
    <Box className={'fleet-dashboard'} px={[1, 1, 5]} py={3} mb={isMobileViewport ? BOTTOM_NAV_HEIGHT : undefined}>
      {isMobileViewport ? (
        <MobileTopNav title={'Fleet Dashboard'} backURL="" />
      ) : (
        <>
          <Flex mt={5} align={'center'}>
            <Heading mr={5} size={'xl'}>
              Fleet Overview
            </Heading>

            <Flex align="center" ml={'auto'} mr={2}>
              <Box mr={4} borderRadius={100} background={iconBackground} shadow="lg">
                <AlertsNotificationIconButton />
              </Box>

              <FleetDashboardProfileInfo />
            </Flex>
          </Flex>

          <Text my={5} fontSize={'md'}>
            Last updated {getDisplayedDateTimeString(lastUpdated)}
          </Text>
        </>
      )}

      {user.role === 'SUPER_ADMIN' && (
        <Center mb={isMobileViewport ? 2 : 4} justifyContent={isMobileViewport ? 'center' : 'flex-start'}>
          <Box w={'200px'} data-testid="tenant-select">
            <TenantSelect
              isDisabled={!isWidgetDataLoaded}
              value={selectedTenantId}
              onChange={(value) => setSelectedTenantId(value as number)}
              includeAllTenants={false}
            />
          </Box>
        </Center>
      )}

      {!isWidgetDataLoaded ? (
        <Box h={'50vh'}>
          <CenteredLoader />
        </Box>
      ) : isNumSitesLoaded && !(numActiveSites + numPendingSites) ? (
        <NewUserDashboardTemplate
          mobileImgSource={FleetChartMobileImg}
          desktopImgSource={FleetChartDesktopImg}
          secondaryText="Your Fleet Dashboard will appear here once you add your first Clipsal Cortex site."
        />
      ) : (
        <>
          {isMobileViewport && (
            <Box>
              <Text mb={2} fontSize={'sm'} textAlign="center">
                Last updated {getDisplayedDateTimeString(lastUpdated)}
              </Text>
              <Box px={2}>
                <DateRangeTypePicker
                  selectedDateRangeType={selectedDateRangeType}
                  setSelectedDateRangeType={(value) => {
                    if (isChartLoaded) setSelectedDateRangeType(value);
                  }}
                />
              </Box>

              <Box rounded={10} bg={background} my={3} px={2}>
                <PeriodPicker
                  isDisabled={!isChartLoaded}
                  rangeType={selectedDateRangeType}
                  selectedPeriod={selectedPeriod}
                  onChangeSelectedPeriod={(newPeriod) => setSelectedPeriod(newPeriod)}
                />
              </Box>
            </Box>
          )}

          <Card p={[0, 0, 5]}>
            {isMobileViewport ? (
              <Heading p={[3, 3, 0]} mb={4} size={isMobileViewport ? 'md' : 'lg'}>
                Fleet Solar Production & Load Consumption
              </Heading>
            ) : (
              <Grid templateColumns={'35% 25% 40%'}>
                <Heading mb={2} size={isMobileViewport ? 'md' : 'lg'}>
                  Fleet Solar Production & Load Consumption
                </Heading>

                <Center my={[2, 2, 0]} h="fit-content" w="100%">
                  <Box minW={300} maxW={300} px={4}>
                    <PeriodPicker
                      isDisabled={!isChartLoaded}
                      rangeType={selectedDateRangeType}
                      selectedPeriod={selectedPeriod}
                      onChangeSelectedPeriod={(newPeriod) => setSelectedPeriod(newPeriod)}
                    />
                  </Box>
                </Center>

                <DateRangeTypePicker
                  selectedDateRangeType={selectedDateRangeType}
                  setSelectedDateRangeType={(value) => {
                    if (isChartLoaded) setSelectedDateRangeType(value);
                  }}
                />
              </Grid>
            )}

            <Flex px={[3, 3, 0]} justify={'space-between'} my={2} w={['100%', '100%', '50%']}>
              <FleetInfoStats
                color={'customOrange.500'}
                title={'Solar Production'}
                value={Math.max(fleetData.produced.total, 0)}
                isLoaded={isChartLoaded}
              />
              <FleetInfoStats
                color={'day.500'}
                title={'Load Consumption'}
                value={Math.max(fleetData.consumed.total, 0)}
                isLoaded={isChartLoaded}
              />
            </Flex>
            {!isChartLoaded ? (
              <Box h={`${CHART_HEIGHT_PIXELS}px`}>
                <CenteredLoader />
              </Box>
            ) : (
              <UsageChart {...{ selectedDateRangeType, selectedPeriod, fleetData }} />
            )}
          </Card>
          <FleetDashboardWidgets
            {...{
              selectedTenantId,
              isWidgetDataLoaded,
              isNumSitesLoaded,
              systemHealthStatus,
              numActiveSites,
              numPendingSites,
            }}
          />
        </>
      )}
    </Box>
  );
}

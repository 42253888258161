import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NewUserDashboardTemplate from '../../common/components/NewUserDashboardTemplate';
import SiteListMobileImg from '../../assets/images/site_list_mobile.svg';
import SiteListDesktopImg from '../../assets/images/site_list_desktop.svg';
import { Box, Button, HStack, Spinner, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { OperatingStatus } from '../../api/api-site-alerts';
import { OPERATING_STATUS_CONFIG } from './dashboard-helpers';
import { useAppDispatch } from '../../app/hooks';
import SitesTable from './SitesTable';
import { getSites, resetDashboard, setLastVisitedSitePath, setNotLoaded } from './dashboardSlice';
import BadgeIcon from './BadgeIcon';
import { reset as resetSite } from '../site/siteSlice';
import { reset as resetWizard } from '../wizard/wizardSlice';
import { resetMeters } from '../site/meter-setup/meterSetupSlice';
import { resetWebSocket } from '../site/meter-setup/webSocketSlice';
import { get } from '../../api/api-helpers';
import { FleetSystemHealthStatus } from '../../api/api-fleet-data';
import { debounceEvent } from '../../utils/component-helpers';
import { FetchDataArgs } from '../../common/components/Table';
import { TenantSelect } from '../../common/components/TenantSelect';
import { selectUser } from '../user/userSlice';
import { useSelector } from 'react-redux';

type State = {
  isNumSitesLoaded: boolean;
  sitesPerOperatingStatus: Record<OperatingStatus, number>;
  operatingStatus: OperatingStatus | 'all';
  searchTerm: string;
  isSearchResultsLoaded: boolean;
  pageSize: number;
  offset: number;
  orderBy: string;
  sortOrder: string; // ASC or DESC
  tenantId: number;
};

export default function SiteList() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const isMobileViewport = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    { ssr: false }
  );
  const defaultTablePageSize = isMobileViewport ? 5 : 10;
  const dispatch = useAppDispatch();
  const [
    {
      isNumSitesLoaded,
      sitesPerOperatingStatus,
      operatingStatus,
      searchTerm,
      pageSize,
      offset,
      sortOrder,
      orderBy,
      isSearchResultsLoaded,
      tenantId,
    },
    setState,
  ] = useState<State>({
    isNumSitesLoaded: false,
    sitesPerOperatingStatus: {
      NORMAL: 0,
      FAULT: 0,
      OFFLINE: 0,
    },
    operatingStatus: (urlParams.get('operatingStatus') || 'all') as OperatingStatus | 'all',
    searchTerm: '',
    isSearchResultsLoaded: true,
    pageSize: Number(urlParams.get('pageSize')) || (isMobileViewport ? 5 : 10),
    offset: Number(urlParams.get('offset')) || 0,
    orderBy: urlParams.get('orderBy') || 'clipsal_solar_id',
    sortOrder: urlParams.get('sortOrder') || 'DESC',
    tenantId: Number(urlParams.get('tenantId')) || 0,
  });
  const totalSites = useMemo(
    () => Object.values(sitesPerOperatingStatus).reduce((a, b) => a + b, 0),
    [sitesPerOperatingStatus]
  );
  const user = useSelector(selectUser);
  const buttonColor = useColorModeValue('white', 'whiteAlpha.200');
  const buttonTextColor = useColorModeValue('black', 'white');

  const handleOperatingStatusChange = (operatingStatus: OperatingStatus | 'all') => {
    setState((state) => {
      return {
        ...state,
        operatingStatus: state.operatingStatus === operatingStatus ? 'all' : operatingStatus,
        offset: 0,
      };
    });

    dispatch(setNotLoaded());
  };

  useEffect(() => {
    // Reset any site related data in the store on load
    dispatch(resetSite());
    dispatch(resetWizard());
    dispatch(resetMeters());
    dispatch(resetDashboard());
    dispatch(resetWebSocket());

    async function fetchNumSites() {
      // Fetch with a limit of 0, so the query is just for COUNT.
      const sitesPerOperatingStatus = await get<FleetSystemHealthStatus>(`/fleet/fleet_operating_status`);
      setState((state) => ({
        ...state,
        sitesPerOperatingStatus,
        isNumSitesLoaded: true,
      }));
    }

    fetchNumSites();
  }, [dispatch]);

  useEffect(() => {
    async function fetchSites() {
      const newPath =
        `/dashboard?pageSize=${pageSize}&offset=${offset}&orderBy=${orderBy}` +
        `&sortOrder=${sortOrder}&operatingStatus=${operatingStatus}&tenantId=${tenantId}`;
      navigate(newPath);
      dispatch(setLastVisitedSitePath(newPath));

      await dispatch(getSites({ pageSize, offset, orderBy, sortOrder, searchTerm, operatingStatus, tenantId }));
      setState((state) => ({ ...state, isSearchResultsLoaded: true }));
    }

    fetchSites();
  }, [dispatch, pageSize, offset, orderBy, sortOrder, searchTerm, operatingStatus, tenantId]);

  const handleUpdatePaginationConfig = useCallback(
    ({
      pageIndex = 0,
      pageSize = defaultTablePageSize,
      orderBy = 'clipsal_solar_id',
      sortOrder = 'DESC',
    }: FetchDataArgs) => {
      setState((prevProps) => ({
        ...prevProps,
        pageSize: pageSize,
        offset: pageIndex * pageSize,
        orderBy,
        sortOrder,
      }));
    },
    [defaultTablePageSize]
  );

  const handleSearch = useMemo(
    () =>
      debounceEvent((e: React.ChangeEvent<HTMLInputElement>) => {
        setState((state) => ({ ...state, searchTerm: e.target.value, isSearchResultsLoaded: false, offset: 0 }));
      }),
    [dispatch]
  );

  const commonTableProps = {
    pageSize,
    offset,
    orderBy,
    sortOrder,
    onFetchData: handleUpdatePaginationConfig,
    onSearch: handleSearch,
    isSearchResultsLoaded,
    searchTerm,
  };

  if (isNumSitesLoaded && !totalSites) {
    return (
      <NewUserDashboardTemplate
        mobileImgSource={SiteListMobileImg}
        desktopImgSource={SiteListDesktopImg}
        secondaryText={
          'You can view all your sites installed with Clipsal Cortex here at a glance.' +
          ' Tap on the button below to get started whenever you’re ready.'
        }
        showGreeting={false}
      />
    );
  }

  return (
    <>
      <HStack px={isMobileViewport ? 2 : 0} wrap="wrap" columnGap={4}>
        {Object.entries(OPERATING_STATUS_CONFIG).map(([key, value]) => {
          const isSelected = key === operatingStatus;
          return (
            <Button
              key={key}
              data-testid={`view-${key}-sites-button`}
              border={'2px solid transparent'}
              color={isSelected ? 'white' : buttonTextColor}
              background={isSelected ? value.badgeColor : buttonColor}
              _hover={{ borderColor: value.badgeColor }}
              _active={{ background: isSelected ? value.badgeColor : buttonColor }}
              shadow="0px 4px 4px rgba(0, 0, 0, 0.1) !important"
              mt={4}
              px={2}
              onClick={() => handleOperatingStatusChange(key as OperatingStatus)}
            >
              <BadgeIcon color={isSelected ? 'white' : value.badgeColor} />
              <Box fontSize={['sm', 'sm', 'md']} ml={2}>
                {value.label} (
                {isNumSitesLoaded ? (
                  sitesPerOperatingStatus[key as keyof typeof sitesPerOperatingStatus]
                ) : (
                  <Spinner color={'primaryBranding.500'} size={'xs'} />
                )}
                )
              </Box>
            </Button>
          );
        })}
        {user.role === 'SUPER_ADMIN' && (
          <Box mt={4} minW={250} data-testid="tenant-select">
            <TenantSelect
              value={tenantId}
              onChange={(value) => setState((state) => ({ ...state, tenantId: value as number }))}
            />
          </Box>
        )}
      </HStack>
      <Box bg="white" _dark={{ bg: 'gray.900' }} mt={4} shadow="sm" borderRadius={8} overflow="hidden" pb={2} mb={20}>
        <SitesTable {...commonTableProps} type={operatingStatus} />
      </Box>
    </>
  );
}

import { ChevronDownIcon } from '@chakra-ui/icons';
import { useColorModeValue, useTheme } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import Select from 'react-select';

interface Option {
  label: string | number;
  value: string | number;
}

interface SelectProps {
  options: Option[] | undefined;
  onChange: (newValue: string | number) => void;
  value?: string | number;
  placeholder?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isLoading?: boolean;
  isOptionsSorted?: boolean;
}

const SearchableDropdown = ({
  options,
  onChange,
  value,
  isDisabled,
  isInvalid,
  isLoading,
  placeholder,
  isOptionsSorted = true,
}: SelectProps) => {
  const sortedOptions = useMemo(() => {
    if (!isOptionsSorted) return options;
    if (!options) return [];
    return [...options].sort((a, b) => {
      const labelOne = a.label.toString().toUpperCase();
      const labelTwo = b.label.toString().toUpperCase();
      if (labelOne < labelTwo) return -1;
      if (labelOne > labelTwo) return 1;
      return 0;
    });
  }, [isOptionsSorted, options]);

  const theme = useTheme();
  const { background, focusedBackground, disabledBackground, iconColor, textColor } = useColorModeValue(
    {
      background: 'white',
      focusedBackground: '#deebff',
      disabledBackground: theme.colors.gray[200],
      iconColor: 'black',
      textColor: 'black',
    },
    {
      background: theme.colors.customBodyBackground[800],
      focusedBackground: theme.colors.gray[700],
      disabledBackground: theme.colors.gray[600],
      iconColor: theme.colors.gray[50],
      textColor: 'white',
    }
  );

  //react-select requires object as value
  const selectedValueObject = useMemo(
    () => options && [...options].find((option) => option.value === value),
    [options, value]
  );

  // custom styling to match chakra-ui form styles
  const customStyles = useMemo(
    () => ({
      option: (provided: Record<any, any>, state: Record<any, any>) => ({
        ...provided,
        backgroundColor: state.isFocused && !state.isSelected ? focusedBackground : provided.backgroundColor,
      }),
      singleValue: (provided: Record<any, any>) => ({ ...provided, color: textColor }),
      menu: (provided: Record<any, any>) => ({ ...provided, background: background }),
      control: (provided: Record<any, any>, state: Record<any, any>) => ({
        ...provided,
        padding: 2,
        borderRadius: 6,
        background: state.isDisabled ? disabledBackground : background,
        boxShadow: isInvalid
          ? state.isFocused
            ? `0 0 0 1px ${theme.colors.customSelectBlue[500]}`
            : `0 0 0 1px ${theme.colors.customRed[500]}`
          : state.isFocused
          ? `0 0 0 1px ${theme.colors.customSelectBlue[500]}`
          : 'none',
        borderColor: isInvalid
          ? state.isFocused
            ? `${theme.colors.customSelectBlue[500]}`
            : `${theme.colors.customRed[500]}`
          : state.isFocused
          ? `${theme.colors.customSelectBlue[500]}`
          : 'gray.200',
        '&:hover': {
          borderColor: isInvalid
            ? state.isFocused
              ? `${theme.colors.customSelectBlue[500]}`
              : `${theme.colors.customRed[500]}`
            : state.isFocused
            ? `${theme.colors.customSelectBlue[500]}`
            : 'gray.200',
        },
      }),
    }),

    //eslint-disable-next-line react-hooks/exhaustive-deps
    [isInvalid, background, textColor]
  );

  return (
    <Select
      styles={customStyles}
      isDisabled={isDisabled}
      isLoading={isLoading}
      value={selectedValueObject || null}
      options={sortedOptions}
      placeholder={placeholder || 'Select...'}
      onChange={(selectedValueObj) => {
        selectedValueObj && onChange(selectedValueObj.value);
      }}
      classNamePrefix="react-select"
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => <ChevronDownIcon w={8} h={5} color={!!selectedValueObject ? iconColor : 'gray'} />,
      }}
      // Elements can get obfuscated by overflow hidden or other CSS styles
      // This is yuck but only this solution worked for me and recommended in GH issue
      // https://github.com/JedWatson/react-select/issues/3263#issuecomment-445809701
      menuPortalTarget={document.querySelector('body')}
    />
  );
};

export default SearchableDropdown;
